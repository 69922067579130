import * as React from "react"
import { Link } from "gatsby"

const UsingSSR = ({ serverData }) => {
  return (
    <div>
     
    </div>
  )
}

export default UsingSSR